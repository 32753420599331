<template>
  <a-modal
    title="操作"
    style="top: 20px;"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item style="display:none">
        <a-input v-decorator="['id']"/>
      </a-form-item>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="权限组名称">
        <a-input v-decorator="[ 'name', { rules: [{ required: true, message: '请输入权限组名称' }]}]" placeholder="请输入权限组名称"/>
      </a-form-item>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="说明" >
        <a-input v-decorator="[ 'remark', { rules: [{ required: false, message: '请输入说明' }]}]" placeholder="请输入"/>
      </a-form-item>
      <!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="是否启用">
        <a-switch v-decorator="[ 'flagEnable', { rules: [{ required: false, message: '请输入说明' }]}]" checked-children="启用" un-checked-children="禁用"/>
      </a-form-item> -->
      <a-divider />
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="拥有权限">
        <div style="max-height: 500px;overflow-y: scroll">
          <a-tree
            checkable
            v-model="checkedKeys"
            @check="onCheck"
            :treeData="permissions"
          >
          </a-tree>
        </div>
      </a-form-item>
    </a-form>

  </a-modal>
</template>

<script>
import { addFirmTemplate, editFirmTemplate} from '../api/FirmTemplateApi'
import pick from 'lodash.pick'

export default {
  name: 'RoleModal',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      visible: false,
      confirmLoading: false,
      mdl: {},

      form: this.$form.createForm(this),
      permissions: [],
      treeCheck: false,
      pidSet: [],
      checkedKeys: [],
      halfCheckedKeys: [],
    }
  },
  created () {
    this.loadPermissions()
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    add () {
      this.form.resetFields()
      this.checkedKeys = []
      this.pidSet = []
      this.edit({ })
    },
    edit (record) {
      if (record.id) {
        // debugger
        this.axios.get('/api/base/firm/firmTemplate/selectById/' + record.id).then(res => {
          if (res.code === 200) {
            const data = res.body.permissionList
            const pidSet = new Set(data.map(m => m.parentId).filter(id => id > 0))
            this.pidSet = pidSet
            // 因为antd 树插件勾选父节点会导致所有子节点选中,所以过滤所有父节点
            this.checkedKeys = data.map(m => m.id).filter(id => !pidSet.has(id))
          }
        })
      }
      this.mdl = Object.assign({}, record)
      // 如果没有check过，半选节点是拿不到的，只能通过预先设置的pidSet获取
      this.treeCheck = false
      this.visible = true
      this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.mdl, 'id', 'name', 'remark'))
      })
    },
    close () {
      this.$emit('close')
      this.visible = false
    },
    onExpand (expandedKeys) {
      console.log('onExpand', expandedKeys)
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck (checkedKeys, info) {
      if (!this.treeCheck) this.treeCheck = true
      this.checkedKeys = checkedKeys
      this.halfCheckedKeys = info.halfCheckedKeys
    },
    onSelect (selectedKeys, info) {
      console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    loadPermissions () {
      this.axios.get('/api/base/firm/firmPermission/list?sourceType=1').then(res => {
        this.buildtree(res.body, this.permissions, 0)
      })
    },
    buildtree (list, permissions, parentId) {
      list.forEach(item => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            title: item.menuName,
            children: []
          }
          this.buildtree(list, child.children, item.id)
          permissions.push(child)
        }
      })
    },
    handleOk (e) {
      const _this = this
      // 如果没有check过，半选节点是拿不到的，只能通过预先设置的pidSet获取
      const checkedAll = this.treeCheck ? _this.checkedKeys.concat(_this.halfCheckedKeys) : _this.checkedKeys.concat(Array.from(_this.pidSet))
      if (!checkedAll.length > 0) {
        _this.$message.warning('请至少选择一个权限')
        return
      }
      // 触发表单验证
      this.form.validateFields((err, values) => {
        // 验证表单没错误
        if (!err) {
          values.permissionIds = checkedAll
          _this.confirmLoading = true
          let url = values.id > 0 ? editFirmTemplate : addFirmTemplate
          url(Object.assign(values)).then(res => {
            if (res.code === 200) {
              _this.$message.success(res.message)
              _this.$emit('ok')
              _this.visible = false
            } else {
              _this.$message.error(res.message)
            }
          }).catch(() => {
            _this.$message.error('系统错误，请稍后再试')
          }).finally(() => {
            _this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
